<!-- <canvas id='wheel' width='1080' height='1080' data-responsiveMinWidth="300" data-responsiveScaleHeight="true" data-responsiveMargin="0"> Canvas not supported, use another browser. </canvas>
<button (click)="calculatePrize()"></button> -->

<div *ngFor="let current of raffles; index as page;">
    <div *ngIf="curPage == page">
        <app-slider *ngIf="current.raffle_type == 'home'" [instance]="raffles" (trigger)="fromMainPage($event)"></app-slider>
        <app-spinner *ngIf="current.raffle_type == 'spinner'" [instance]="current" (trigger)="fromChildInit($event)"></app-spinner>
        <app-wheel *ngIf="current.raffle_type == 'wheel'" [instance]="current" (trigger)="fromChildInit($event)"></app-wheel>
        <app-mosaic *ngIf="current.raffle_type == 'mosaic'" [instance]="current" (trigger)="fromChildInit($event)"></app-mosaic>
    </div>
</div>

<div class="floating-control">
    <button (click)="prevPage()" class="btn"><<</button>
    <a class="center-content down text-white" (click)="backToMainPage()">
        <img src="assets/images/event/image-down.png" alt="" class="scroll-down">
        <!-- <span style="font-size: 0.75em; padding-top: 5px;">Back to Menu</span> -->
    </a>
    <button (click)="nextPage()" class="btn">>></button>
</div>