<ng-template #content let-modal>
	<div class="modal-body modal-image" [ngStyle]="{'background-image': modalImg()}">
    <button class="modal-close" type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
		<p class="modal-winner">{{message}}</p>
    <p class="modal-raffle">{{raffle}}</p>
	</div>
</ng-template>

<section class="event header" [ngStyle]="{'background-image': 'url(assets/images/event/wheel-bg.jpg)'}">
    <div class="header3 bg">
        <div #spinners id="app" [@bounce]="{ value: bounce }">
            <div class="back" [ngStyle]="{'background-image': 'url(assets/images/event/header.png)'}"></div>
            <div class="doors">
              <div class="door" *ngFor="let slot of slots">
                <div class="boxes" [style.transform]="getTranslate(slot)">
                    <div class="box" *ngFor="let item of items" [style.filter]="getFilter(slot)" [ngStyle]="{'background-image': 'url(assets/images/event/slot.png)'}"><h1 class="slot-text" >{{item}}</h1></div>
                </div>
              </div>
            </div>
            <div class="buttons">
              <div><h2 style="color: #e8268c;">{{instance.title}}</h2></div>
            </div>
            <div class="buttons">
              <button class="btn play" (click)="startSpinnerGame()" [disabled]="active || completed">START DRAW</button>
            </div>
            <div style="z-index: 999; margin-top: 10px; width: 100vw; padding: 0 4em; margin-bottom: 1.5em;">
              <h4 class="win-title">ALL WINNERS ({{allWinners?.length}}/{{instance.winners}})</h4>
              <div style="display: -webkit-box; overflow-x: scroll;">
                <div *ngFor="let win of allWinners; index as page;" class="winbox text-white">
                  {{win.user_name}}
                </div>
              </div>
            </div>
          </div>
    </div>
</section>
