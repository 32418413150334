import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
	//  {
   //    title: 'Verify', type: 'sub', children: [
   //       { path: '/scan-qr', title: 'Scan QR', type: 'link' },
   //       // { path: '/signout', title: 'Signout', type: 'link' }
   //    ],
   //  },
   //  {
	// 		title: 'Blog', type: 'sub', children: [
   //      { 
	//       	title: 'Blog Grid View',  type: 'sub', children: [
   //             { path: '/blog/blog-grid/column-no-sidebar', title: 'No Sidebar',  type: 'link' },
   //             { path: '/blog/blog-grid/column-left-sidebar', title: 'Left Sidebar',  type: 'link' },
   //             { path: '/blog/blog-grid/column-right-sidebar', title: 'Right Sidebar',  type: 'link' },
   //             { path: '/blog/blog-grid/classic-no-sidebar', title: 'Full Width No Sidebar',  type: 'link' },
   //             { path: '/blog/blog-grid/classic-right-sidebar', title: 'Full Width Right Sidebar',  type: 'link' },
   //             { path: '/blog/blog-grid/classic-left-sidebar', title: 'Full Width Left Sidebar',  type: 'link' }
	//       	]
   //      },
   //      {
   //         title: 'Blog List',  type: 'sub', children: [
   //             { path: '/blog/blog-list/list-no-sidebar', title: 'List No Sidebar',  type: 'link' },
   //             { path: '/blog/blog-list/list-left-sidebar', title: 'List Left Sidebar',  type: 'link' },
   //             { path: '/blog/blog-list/list-right-sidebar', title: 'List Right Sidebar',  type: 'link' }]
   //      },
   //      {
   //       title: 'Blog List Creative',  type: 'sub', children: [
   //          { path: '/blog/blog-list-creative/split-no-sidebar', title: 'No Sidebar',  type: 'link' },
   //             { path: '/blog/blog-list-creative/split-left-sidebar', title: 'Left Sidebar',  type: 'link' },
   //             { path: '/blog/blog-list-creative/split-right-sidebar', title: 'Right Sidebar',  type: 'link' }]
   //       },
   //       {
   //          title: 'Blog Mix Layout',  type: 'sub', children: [
   //                { path: '/blog/blog-mix-layout/bigpost-list', title: 'List Mix With Left Sidebar',  type: 'link' },
   //                { path: '/blog/blog-mix-layout/bigpost-split', title: 'List Mix With Right Sidebar',  type: 'link' },
   //                { path: '/blog/blog-mix-layout/bigpost-split-no-sidebar', title: 'List Mix With No Sidebar',  type: 'link' },
   //                { path: '/blog/blog-mix-layout/bigpost-list-no-sidebar', title: 'List Full Width No Sidebar',  type: 'link' },
   //                { path: '/blog/blog-mix-layout/bigpost-column', title: 'Grid Mix With Right Sidebar',  type: 'link' },
   //                { path: '/blog/blog-mix-layout/bigpost-column-no-sidebar', title: 'Grid Mix With No Sidebar',  type: 'link' }
   //          ]},
   //          { 
   //             title: 'Blog Details',  type: 'sub', children: [
   //                { path: '/blog/blog-detail/detail-left-sidebar', title: 'Left Sidebar',  type: 'link' },
   //                { path: '/blog/blog-detail/detail', title: 'Right Sidebar',  type: 'link' },
   //                { path: '/blog/blog-detail/detail-no-sidebar', title: 'No Sidebar',  type: 'link' },
   //                { path: '/blog/blog-detail/detail-gallery', title: 'Detail Page With Gallery',  type: 'link' },
   //                { path: '/blog/blog-detail/detail-video', title: 'Detail Page With Video',  type: 'link' },
                  
   //             ]
   //          },
   //       { 
   //       title: 'Blog Masonary',  type: 'sub', children: [
   //          { path: '/blog/blog-masonary/fullwidth-2', title: 'Full Width 2',  type: 'link' },
   //          { path: '/blog/blog-masonary/fullwidth-3', title: 'Full Width 3',  type: 'link' },
   //          { path: '/blog/blog-masonary/fullwidth-4', title: 'Full Width 4',  type: 'link' },
   //          { path: '/blog/blog-masonary/masonary-no-sidebar', title: 'No Sidebar',  type: 'link' },
   //          { path: '/blog/blog-masonary/masonary-right-sidebar', title: 'Right Sidebar',  type: 'link' },
   //          { path: '/blog/blog-masonary/masonary-left-sidebar', title: 'Left Sidebar',  type: 'link' }
   //       ]
   //       },
   
   //    ]
   //  },
   //  {
	// 		title: 'Pages', type: 'sub', children: [
   //          { path: '/404', title: '404',  type: 'link' },
   //          { path: '/page/faq', title: 'FAQ',  type: 'link' },
   //          { path: '/page/typography', title: 'Typography',  type: 'link' },
   //          { path: '/maintenance', title: 'Maintenance',  type: 'link' },
   //          { path: '/page/about-us', title: 'About-us',  type: 'link' },

   //       { title: 'Team',  type: 'sub', children: [
   //             { path: '/page/team', title: 'Team',  type: 'link' },
   //             { path: '/page/team-grid', title: 'Team Grid',  type: 'link' },
   //             { path: '/page/team-list', title: 'Team List',  type: 'link' }]
   //       },
   //       { title: 'Comming Soon',  type: 'sub', children: [
         
   //          { path: '/commingsoon1', title: 'Comming Soon1',  type: 'link' },
   //          { path: '/commingsoon2', title: 'Comming Soon2',  type: 'link' }]
   //       }]
   //  },
   //  { 
   //    title: 'Features',  megaMenu: true, megaMenuType: 'medium',  type: 'sub', children: [
   //     { title: 'Header Style',  type: 'link', children: [
   //       { path: '/features/header-light', title: 'Light Header',  type: 'link' },
   //       { path: '/features/header-dark', title: 'Dark Header',  type: 'link' },
   //        { path: '/features/header-transparent', title: 'Glass Header',  type: 'link' },
   //        { path: '/features/header-right-navigation', title: 'Header Right Navigation',  type: 'link' },
   //        { path: '/features/header-center-logo', title: 'Header Center Logo',  type: 'link' }]
   //     },
   //     { title: 'Breadcrumb Style',  type: 'link', children: [
   //        { path: '/features/breadcrumb-big-typography', title: 'Classic Type',  type: 'link' },
   //        { path: '/features/breadcrumb-left', title: 'Breadcrumb Left',  type: 'link' },
   //        { path: '/features/breadcrumb-right', title: 'Breadcrumb Right',  type: 'link' },
   //        { path: '/features/breadcrumb-center', title: 'Breadcrumb Center',  type: 'link' },
   //        { path: '/features/breadcrumb-dark', title: 'Breadcrumb Dark',  type: 'link' },
   //        { path: '/features/breadcrumb-parallex-bg', title: 'Breadcrumb Parallex-Bg',  type: 'link' },
   //        { path: '/features/breadcrumb-bg', title: 'Breadcrumb Bg',  type: 'link' },
   //        { path: '/features/breadcrumb-gallery', title: 'Breadcrumb Gallery',  type: 'link' },
   //        { path: '/features/breadcrumb-video', title: 'Breadcrumb Video',  type: 'link' }]
   //     },
   //     { title: 'Footer Style',  type: 'link', children: [
   //       { path: '/features/footer-default', title: 'Footer-Default',  type: 'link' },
   //       { path: '/features/footer-light', title: 'Footer-Light',  type: 'link' },
   //       { path: '/features/footer-dark', title: 'Footer-Dark',  type: 'link' },
   //       { path: '/features/footer-light2', title: 'Footer Dark Light',  type: 'link' },
   //       { path: '/features/footer-dark2', title: 'Footer Dark Gradient',  type: 'link' },
   //       { path: '/features/footer-dark3', title: 'Footer Creative',  type: 'link' }]

   //    },
   //     { title: 'Gallery',  type: 'link', children: [
   //        { path: '/features/zoom-gallery', title: 'Zoom Gallery',  type: 'link' },
   //        { path: '/features/form-popup', title: 'Form Popup',  type: 'link' },
   //        { path: '/features/modal-popup', title: 'Modal Popup',  type: 'link' },
   //        { path: '/features/youtube-popup', title: 'Youtube Popup',  type: 'link' },
   //        { path: '/features/map-popup', title: 'Map Popup',  type: 'link' }]
   //     }
   //    ]
   //  },
   //  {
   //    title: 'About Us', path: '/about-us', type: 'link'
   //  }
   ]
  
  	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
   
}
