<!-- about section Start-->
<section class="event about bg bg-img bg-about" [ngStyle]="{'background-image': 'url(assets/images/event/bg1.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3" style="margin-bottom: 80px;">
                    <div class="main-title">
                        <h2 class=" text-white" style="font-family: BrandOtf;">About the Raffle</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-white">Enter, Win, Celebrate! Participate in our raffles for a chance to score amazing prizes. Embark on an exhilarating journey of anticipation and possibility, as you chase after the coveted prizes that await in our raffles. Join now and let the excitement ignite!</P>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 offset-lg-1 col-md-6">
                <div class="text-right">
                    <div class="announcer-img">
                        <img src="assets/images/event/1.jpg" alt="" class="img-fluid" style="display: none;">
                        <img src="assets/images/event/1.jpg" alt="" class="img-fluid" style="display: none;">
                        <img src="assets/images/event/about.png" alt="" class="img-fluid" aTilt data-tilt data-tilt-max="3"
                             data-tilt-speed="400" data-tilt-perspective="500">
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-lg-7 col-md-6">
                <div class="abouts center-text">
                    <div class="format">
                        <div class="format-small-text">
                            <h6 class="text-white borders-before text-uppercase"><span>The Brilliant Raffle 2023</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header text-white">#Pinakamapagbigay</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para p-light">The much awaited raffle event by Brilliant Skin Essentials, Inc., is finally happening!</p>
                            <p class="about-para p-light">Come and join us as we return <span>BIGGER</span>, <span>BOLDER</span>, and <span>BRIGHTER</span>! With the special participation of various brands and collabs.</p>
                            <p class="about-para p-light">Don’t miss the chance to win exciting <span>prizes</span> and be part of our biggest raffle!</p>
                        </div>
                        <!-- <a class="btn btn-default " href="#">read more</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
