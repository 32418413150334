<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Notification</h4>
		<!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
	</div>
	<div class="modal-body">
		<p style="text-align: center; padding: 20px 0; color: #3f3f3f;">{{notif}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<!-- booking Start-->
<section class="event booking set-relative" id="register">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 style="font-family: BrandOtf;">{{raffle_title}}</h2>
                        <h6 style="padding: 15px; color: #4b4b4b;">RAFFLE DETAILS</h6>
                    </div>
                    <div class="sub-title">
                        <p class="event-description" style="color: #4b4b4b;" [innerHTML]="raffle_description"></p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-inline m-b-20">
                    <div class="col-sm-6 m-b-20">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="first_name" placeholder="first name">
                        </div>
                    </div>
                    <div class="col-sm-6 m-b-20">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="last_name" placeholder="last name">
                        </div>
                    </div>
                    <div class="col-sm-8 m-b-20">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="email" placeholder="email">
                        </div>
                    </div>
                    <div class="col-sm-4 m-b-20">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="phone" placeholder="phone">
                        </div>
                    </div>
                    <div class="col-sm-12 m-b-20">
                        <div class="form-group">
                            <textarea [(ngModel)]="remarks" placeholder="optional! If you are the lucky winner of this raffle, kindly share your message with us in one to three sentences." rows="4" cols="50"></textarea>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <ngb-alert type="info" [dismissible]="false" *ngIf="greeting">
                        <strong>Congrats!</strong> {{greeting}}
                    </ngb-alert>
                    <ngb-alert type="warning" [dismissible]="false" *ngIf="message">
                        <strong>Warning!</strong> {{message}}
                    </ngb-alert>
                </div>
                <div class="text-center">
                    <a class="btn btn-default primary-btn m-0-auto" (click)="submit_entry()">Join Raffle</a>
                </div>
                <div class="text-center" style="margin-top: 20px;">
                    <a class="m-0-auto" (click)="back_to_home()">Back to Home</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- booking end-->
