import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  OnInit, AfterViewInit,
  Input, Output, EventEmitter
} from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { trigger, transition, useAnimation, AnimationBuilder } from '@angular/animations';
import { bounce, pulse, flash } from 'ng-animate';
import { ConfettiService } from '../../../services/confetti.service';

declare var Winwheel: any;

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))])
  ],
})
export class WheelComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;
  @ViewChild('wheelContainer') wheelContainer: ElementRef;
  @ViewChild('wheelcanvas') wheelcanvas: ElementRef<HTMLElement>;

  theWheel;
  bounce: any;
  message: any;

  private winner: number = 0;
  private segments: any[] = [];

  @Input() instance: any;
  @Output() trigger = new EventEmitter<any>();

  constructor(
    private config: NgbModalConfig,
    private modal: NgbModal,
    private confetti: ConfettiService,
    private animationBuilder: AnimationBuilder
  ) {
    config.backdrop = 'static';
		config.keyboard = false;
  }

  ngOnInit(): void {
    console.log(this.instance);
    this.trigger.emit(this.instance);
  }
  
  async ngAfterViewInit() {
    //TODO: Get the top 20-1 of the participant.
    for(var i=0; i<12; i++) {
      if(i % 2 == 0) {
        this.segments.push({
          'fillStyle': '#ffffff',
          'text': 'Segment '+i,
          'textFontSize': 16, 
          'textFillStyle': '#3d3d3d'
        });
      } else {
        this.segments.push({
          'fillStyle': '#3d3d3d',
          'text': 'Segment '+i,
          'textFontSize': 16, 
          'textFillStyle': '#ffffff'
        });
      }
    }

    //TODO: Generate winner from server.
    this.winner = 4; //await from server.

    this.drawWheel();
  }

  playAnim(element: HTMLElement): void {

    const animPlayer = this.animationBuilder
      .build(pulse)
      .create(element);//, { params: { ...this.params } });

    animPlayer.init();
    animPlayer.onDone(() => {
      animPlayer.destroy();
    });
    animPlayer.play();
  }

  startSpinWheel() {
    let stopAt = this.theWheel.getRandomForSegment(this.winner);
    this.theWheel.animation.stopAngle = stopAt;
    this.theWheel.startAnimation();
  }

  showGreeting(message: any) {
    this.message = message;
    this.modal.open(this.content, {centered: true});
  }

  onSpinCompleted(segment) {
    this.onPinBlink();
    this.showGreeting('The winner for the {{RAFFLE DRAW TITLE HERE}} is '+segment.text+'.');

    this.playAnim(this.wheelcanvas.nativeElement);
    this.confetti.showConfettiSide();
    this.confetti.showConfettiStar();
  }

  drawWheel() {
    this.theWheel = new Winwheel({
        'canvasId': 'wheel',
        'numSegments': this.segments.length,
        'segments': this.segments,
        //'centerX'     : 200,    // correctly position the wheel
        //'centerY'     : 201,    // over the background.
        'lineWidth': 1,
        'outerRadius': 250,
        'innerRadius': 25,
        'pointerAngle': 0,
        'textAlignment': 'outer',
        'textMargin': '16',
        'responsive': true,
        'animation': {
          'type'     : 'spinToStop',
          'duration' : 15,     // Duration in seconds.
          'spins'    : 30,     // Number of complete spins.
          'callbackSound': this.spinSound,
          'callbackFinished': (segment) => {
            this.onSpinCompleted(segment)
          },
          'soundTrigger'     : 'pin'        // Specify pins are to trigger the sound.
        },
        'pins' : {
            'number'     : this.segments.length,
            'fillStyle'  : 'yellow',
            'outerRadius': 10,
        }
    });
  }

  spinSound() {
    let audio = new Audio();
    audio.src = "assets/sounds/tick.mp3";
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    console.log(event.key);
    if(event.key == " ") {
      this.startSpinWheel();
    }
  }

  //Working only while winnner is not declared.
  onPinBlink() {
    setInterval(()=> {
      let color = this.theWheel.pins.fillStyle;
      if(color == "orange") {
        this.theWheel.pins.fillStyle = "yellow";
      }
      if(color == "yellow") {
        this.theWheel.pins.fillStyle = "orange";
      }   
      this.theWheel.draw(); //force to update.
    }, 500);
  }
}
