import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApisService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit {

  public formEnabled: boolean = false;

  public greeting: any;
  public message: any;

  public first_name: any = '';
  public last_name: any = '';
  public phone: any = '';
  public email: any = '';
  public remarks: any = '';

  private pid: any = '';
  private uid: any = '';

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private api: ApisService
  ) { 
    this.router.params.subscribe( data => {
      this.validate_entry(data.id);
    });
  }

  ngOnInit(): void {
  }

  validate_entry(id: any) {
    //console.log(id);

    //If empty then redirect.
    if(id === '') {
      this.route.navigateByUrl('/');
    }

    //api check to validate if actual participant and used.
    this.api.posts('/v1/api/EventRaffle/validate_entry', {
      entry_id: id
    }).then((res: any) => {
      if(res && res.success) {
        this.pid = res.id;
        this.uid = res.uuid;
      } else {
        this.route.navigateByUrl('/');
      }
    }).catch(error => {
      this.route.navigateByUrl('/');
      console.log('error', error);
    });

    //if the participant id is validated and is not yet assigned to a user, then enable the form.
    this.formEnabled = true; 
  }

  submit_entry() {
    //api check to validate if actual participant and used.
    this.api.posts('/v1/api/EventRaffle/submit_entry', {
      id: this.pid, 
      entry_id: this.uid,
      first_name: this.first_name,
      last_name: this.last_name,
      email_address: this.email,
      phone_number: this.phone,
      remarks: this.remarks
    }).then((res: any) => {
      if(res && res.success) {
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone = '';
        this.remarks = '';
        
        this.greeting = "Successfully verified your qrcode, check your email to see the result.";
      } else {
        this.message = res.message;
      }
    }).catch(error => {
      this.message = "Something went wrong!";
      console.log('error', error);
    });
  }

  back_to_home() {
    this.route.navigateByUrl('/');
  }
}
