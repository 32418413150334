import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { rubberBand } from 'ng-animate';
import { ConfettiService } from '../../../services/confetti.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(rubberBand))])
  ],
})
export class SliderComponent implements OnInit, OnDestroy {
  bounce: any;
  
  public bookingOffset: any = null;

  end: any = new Date();

  public day: any = 0;
  public hour: any = 0;
  public min: any = 0;
  public sec: any = 0;

  _second: any;
  _minute: any;
  _hour: any;
  _day: any;
  timer;

  private countdown: any;

  @Input() instance: any;
  @Output() trigger = new EventEmitter<any>();

  constructor(
    private route: Router,
    private confetti: ConfettiService
  ) {
    this.end = this.addMinutes(this.end, 0.1);
  }

  raffles: any[];

  ngOnInit(): void {
    this.raffles = JSON.parse(JSON.stringify(this.instance));
    this.raffles.shift(); //remove home

    this.raffles.forEach(element => {
      let crowd_list = "";
      let crowd = element.crowd_type.split(",");
      crowd_list = crowd.shift().toUpperCase();
      let last = crowd.pop();
      crowd.forEach(element => {
        crowd_list += " | "+element.toUpperCase();
      });
      crowd_list += " | "+last.toUpperCase();
      element.crowd_type = crowd_list;
    });
  }

  onClickTitle(uuid: any) {
    this.trigger.emit(uuid);
  }

  addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  onDrawTimeout() {    
    //this.confetti.showConfettiSide(); //show visual
    //this.confetti.showConfettiStar();
  }

  showRemaining() {
    this._second = 1000;
    this._minute = this._second * 60;
    this._hour = this._minute * 60;
    this._day = this._hour * 24;

    let now: any = new Date();
    let distance = this.end - now;
    if (distance < 0) {
        clearInterval(this.countdown);
        this.onDrawTimeout();
        return;
    }
    var days = Math.floor(distance / this._day);
    var hours = Math.floor((distance % this._day) / this._hour);
    var minutes = Math.floor((distance % this._hour) / this._minute);
    var seconds = Math.floor((distance % this._minute) / this._second);

    this.day = days;
    this.hour = hours;
    this.min = minutes;
    this.sec = seconds;
}

  onRedirect(e) {
    e.preventDefault();
    let el = document.getElementById('register');
    el.scrollIntoView({behavior: 'smooth'});
  }

  ngOnDestroy() {

  }
  
  logout() {
    localStorage.clear();
    this.route.navigateByUrl('/login');
  }
}
