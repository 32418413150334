import { Component, ElementRef, HostListener, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { trigger, transition, useAnimation, AnimationBuilder } from '@angular/animations';
import { bounce, pulse, tada } from 'ng-animate';
import { ConfettiService } from '../../../services/confetti.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApisService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))])
  ],
})
export class SpinnerComponent implements OnInit {
  @ViewChild('spinners') spinners: ElementRef<HTMLElement>;
  @ViewChild('content') content: ElementRef;
  bounce: any;

  slots: any[] = [
    {
      id: "a",
      active: false,
      value: "",
      position: 0
    },
    {
      id: "b",
      active: false,
      value: "",
      position: 0
    },
    {
      id: "c",
      active: false,
      value: "",
      position: 0
    },
    {
      id: "d",
      active: false,
      value: "",
      position: 0
    },
    {
      id: "e",
      active: false,
      value: "",
      position: 0
    },
    {
      id: "f",
      active: false,
      value: "",
      position: 0
    },
    {
      id: "g",
      active: false,
      value: "",
      position: 0
    },
    {
      id: "h",
      active: false,
      value: "",
      position: 0
    },
  ];

  items: any[] = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
    "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"
  ];
  active: any = false;
  curPos: any = 0;

  timer: any;
  counter: any;
  curSlot: any = 0;

  message: any;
  raffle: any;
  epass: any;
  winner: any;
  winners: any;

  @Input() instance: any;
  @Output() trigger = new EventEmitter<any>();

  constructor(
    private config: NgbModalConfig,
    private modal: NgbModal,
    private animationBuilder: AnimationBuilder,
    private confetti: ConfettiService,
    private api: ApisService
  ) {
    config.backdrop = 'static';
		config.keyboard = false;
  }

  ngOnInit(): void {
    //console.log(this.instance);
    //this.trigger.emit(this.instance);
    
    setTimeout(() => {
      this.loadRaffle();
    }, 100);
  }

  allWinners: any[] = [];
  completed: any = false;

  modalImg() {
    return 'url(assets/images/prize/laborday.png)';
    if( this.instance.title.includes("MG CAR") ) {
      return 'url(assets/images/prize/car-mg.png)';
    } else if(this.instance.title.includes("VIOS CAR")) {
      return 'url(assets/images/prize/car-vios.png)';
    } else {
      return 'url(assets/images/prize/motor.png)';
    }
  }

  async loadRaffle() {
    this.api.posts('/v1/api/EventRaffle/load_raffle', {
      id: this.instance.id
    }).then((res: any) => {
      if(res && res.raffle && res.winners && res.total_participants) {
        if(res.winners.length >= res.raffle.winners) {
          this.active = true; //disable draw.
          this.completed = true;
        }
        
        this.allWinners = res.winners;
      }
    }).catch(error => {
      console.log('error', error);
    });
  }

  startSpinnerGame() {
    if(this.active) {
      return;
    }
    this.active = true;

    this.api.posts('/v1/api/EventRaffle/play_raffle', {
      id: this.instance.id
    }).then((res: any) => {
      if(res && res.success && res.winners) {

        this.winners = res.winners;

        this.winner = res.winners[0];
        this.epass = {
          uuid: this.winner.uuid.toLowerCase(),
          fullname: this.winner.user_name
        };

        for(var i=0; i<8; i++) {
          this.slots[i].value = this.epass.uuid[i];
        }

        this.startPlay();
      } else {
        this.active = false;
        console.log(res.message);
      }
    }).catch(error => {
      console.log('error', error);
    });
  }

  onPinnerCompleted() {
    this.winners.forEach(winner => {
      this.allWinners.push(winner);
    });
    this.confetti.showConfettiFirewords();
    this.playAnim(this.spinners.nativeElement);
    //this.showGreeting(this.winner.user_name.toUpperCase());
  }

  showGreeting(message: any) {
    this.message = message;
    this.raffle = this.instance.title;
    this.modal.open(this.content, {centered: true});
  }

  playAnim(element: HTMLElement): void {

    const animPlayer = this.animationBuilder
      .build(tada)
      .create(element);//, { params: { ...this.params } });

    animPlayer.init();
    animPlayer.onDone(() => {
      animPlayer.destroy();
    });
    animPlayer.play();
  }

  getTranslate(item) {
    if(!this.active && this.completed) {
      return "translateY(-0%)";
    }

    if(item.active == true) {
      return "translateY(-"+item.position+"%)";
    }

    let curIndex: any = 0;
    let curElement: any = this.items.map((curElem, index) => {
      if(item.value.toLowerCase() == curElem.toLowerCase()) {
        curIndex = index;
        return curElem; //equivalent to list[index]
      }
      return null;
    });

    let pos = (curIndex*2.777) % 100;
    return "translateY(-"+pos+"%)";
  }

  getFilter(item) {
    if(item.active == true && this.active) {
      return "blur(3px)";
    }
    return "unset";
  }

  spinSound() {
    let audio = new Audio();
    audio.src = "assets/sounds/tick.mp3";
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  }

  startPlay() {
    this.curSlot = 0;

    //Enable all
    this.slots.forEach((item)=> {
      item.active = true;
    });
    
    let timer = 0;
    //Spinning
    this.timer = setInterval(() => {
      this.slots.forEach((item)=> {
        item.position += 1;
        if(item.position >= 100) {
          item.position = 0;
        }
        if(item.active == true) {
          timer += 1;
          if(timer >= 5) {
            this.spinSound();
            timer = 0;
          }
        }
      });
    }, 40);

    //One by one disable
    this.counter = setInterval(() => {
      if(this.curSlot < this.slots.length) {
        this.slots[this.curSlot].active = false;
        this.curSlot += 1;
      } else {
        this.stopPlay();
        this.onPinnerCompleted();
      }
    }, 1000);
  }

  stopPlay() {
    clearInterval(this.timer);
    clearInterval(this.counter);

    if(this.allWinners.length >= this.instance.winners) {
      this.active = true;
      this.completed = true;
    } else {
      this.active = false;
    }
  }

  getAllColumns() {
    let style = "";
    let size = 100/this.allWinners?.length;
    this.allWinners.forEach((elem) => {
      style += size+"% ";
    })
    return style;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if(event.key == " ") {
      this.startSpinnerGame();
    }

    if(event.key == "x") {
      this.modal.dismissAll();
    }
  }
}