<!-- header Start-->
<section class="event header" id="home">
    <div class="header3 bg" [ngStyle]="{'background-image': 'url(assets/images/event/event_slider.jpg)'}">
        <div class="event-content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-md-10 offset-md-1">
                        <div class="center-text" [@bounce]="{ value: bounce }">
                            <div class="text-center w-100">
                                <div class="h1-margin">
                                    <h2 class="header-title text-white"><span style="font-size: larger;">TRIPLE SAYA DRAW</span></h2>
                                    <h4 style="color: white;">- Raffle App -</h4>
                                </div>
                                <div class="wedding-time">
                                    <div class="row">
                                        <div class="col-sm-4 col-12">
                                            <div class="countdown-container">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{hour}}</span>
                                                        </h2>
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="days text-white">HOURS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-12">
                                            <div class="countdown-container">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{min}}</span>
                                                        </h2>
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="days text-white">MINUTES</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-12">
                                            <div class="countdown-container">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{sec}}</span>
                                                        </h2>
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="days text-white">SECONDS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 class="text-white zexon" style="margin-bottom: 10px; font-weight: 300;">
                                    <!-- <i class="fa fa-map-marker m-r-10 text-white" aria-hidden="true"></i>  -->
                                    Coming Up Next...
                                </h5>
                                <h4 class="text-white zexon" style="margin-bottom: 5px; font-weight: 400;">
                                    BIG STAR MAMBA JUMBO
                                </h4>
                                <h5 class="text-white zexon" style="margin-bottom: 18px; font-weight: 200;">
                                    <!-- <i class="fa fa-map-marker m-r-10 text-white" aria-hidden="true"></i>  -->
                                    Pinakamakinang 2023
                                </h5>
                                <h5 class="header-sub-text text-white">
                                    <!-- <i class="fa fa-calendar m-r-10" aria-hidden="true"></i> -->
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit odio, tempor nec turpis eget, semper tempor mauris. In suscipit, augue sit amet...
                                </h5>
                                <!-- <div class="text-center link-horizontal">
                                    <ul>
                                        <li>
                                            <a class=" btn btn-default back-white text-uppercase border-not" href="#">view event details</a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="set-bottom set-abs">
            <a class="center-content down" (click)="onRedirect($event)">
                <img src="assets/images/event/image-down.png" alt="" class="scroll-down">
            </a>
        </div>
    </div>
</section>
<!-- header end-->