<!--schedule section start-->
<section class="event schedule set-relative" id="schedule">
    <!-- <div class="circle">
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 style="font-family: BrandOtf;"><span>Today's</span> Schedule</h2>
                    </div>
                    <div class="sub-title">
                        <P style="color: #4b4b4b;">Discover your lucky day Immerse yourself in the excitement as we present a lineup of exclusive raffles, specially curated for you. Join us for a chance to win amazing prizes and experiences that will leave you exhilarated. </P>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="cal-time">
                    <div class="time">
                        <h4 class="text-capitalize monday-text">{{getDateToday()}}</h4>
                    </div>
                </div>
                <div class="event-container scrollbar">
                    <div>
                        <div class="cal-time events" *ngFor="let data of schedule">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="schedule-event">
                                        <h5 class="names">{{data.name}}</h5>
                                        <h6 class="event">{{data.event}}</h6>
                                        <!-- <img [src]="data.icon" alt="" class="img-fluid schedule-img"> -->
                                        <p class="event-description" [innerHTML]="data.description"></p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="timing">
                                        <h5 >{{data.time}}</h5>
                                        <div class="border-container m-t-30"></div>
                                        <h5 class="event-time">{{data.winners}} winners</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--schedule section end-->
