import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-speaker',
  templateUrl: './event-speaker.component.html',
  styleUrls: ['./event-speaker.component.scss']
})
export class EventSpeakerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  experts = [
    { 
     img:"assets/images/event/speaker/1-3.jpg",
     name:"Korina Sanchez",
     designation:"GUEST"
    },
    { 
      img:"assets/images/event/speaker/2-3.jpg",
      name:"Raffy Tulfo",
      designation:"GUEST"
     },
     { 
      img:"assets/images/event/speaker/3-3.jpg",
      name:"Jet Lee",
      designation:"GUEST"
     },
     { 
      img:"assets/images/event/speaker/4-3.jpg",
      name:"Zeinab Harake",
      designation:"GUEST"
     },
     { 
      img:"assets/images/event/speaker/5-3.jpg",
      name:"Andrew Brilliantes",
      designation:"GUEST"
      },
      { 
        img:"assets/images/event/speaker/6-3.jpg",
        name:"Seth Fedeline",
        designation:"GUEST"
      },
      { 
        img:"assets/images/event/speaker/7-3.jpg",
        name:"Rabiya Mateo",
        designation:"GUEST"
      },
      { 
        img:"assets/images/event/speaker/8-3.jpg",
        name:"Jilliane Ward",
        designation:"GUEST"
       },
  ]

}
