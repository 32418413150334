import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { rubberBand } from 'ng-animate';
import { ConfettiService } from '../../../services/confetti.service';

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(rubberBand))])
  ],
})
export class EventHeaderComponent implements OnInit, OnDestroy {
  bounce: any;
  
  public bookingOffset: any = null;

  end: any = new Date();

  public day: any = 0;
  public hour: any = 0;
  public min: any = 0;
  public sec: any = 0;

  _second: any;
  _minute: any;
  _hour: any;
  _day: any;
  timer;

  private countdown: any;

  constructor(
    private confetti: ConfettiService
  ) {
    this.end = this.addMinutes(this.end, 0.1);
  }

  addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  onDrawTimeout() {    
    //this.confetti.showConfettiSide(); //show visual
    //this.confetti.showConfettiStar();
  }

  showRemaining() {
    this._second = 1000;
    this._minute = this._second * 60;
    this._hour = this._minute * 60;
    this._day = this._hour * 24;

    let now: any = new Date();
    let distance = this.end - now;
    if (distance < 0) {
        clearInterval(this.countdown);
        this.onDrawTimeout();
        return;
    }
    var days = Math.floor(distance / this._day);
    var hours = Math.floor((distance % this._day) / this._hour);
    var minutes = Math.floor((distance % this._hour) / this._minute);
    var seconds = Math.floor((distance % this._minute) / this._second);

    this.day = days;
    this.hour = hours;
    this.min = minutes;
    this.sec = seconds;
}

  onRedirect(e) {
    e.preventDefault();
    let el = document.getElementById('register');
    el.scrollIntoView({behavior: 'smooth'});
  }

  ngOnInit() {
    this.countdown = setInterval(() => {
      this.showRemaining();
    }, 1000)
  }

  ngOnDestroy() {
  }
  
}
