<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-center">CONGRATULATIONS!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p style="text-align: center; padding: 20px 0; color: #3f3f3f;">{{message}}</p>
	</div>
	<div class="modal-footer" style="display: block; font-size: 10px;">
		<div class="text-center text-black">Made Possible by BytesCrafter</div>
	</div>
</ng-template>

<section class="event header" [ngStyle]="{'background-image': 'url(assets/images/event/wheel-bg.jpg)'}">
    <div class="header3 bg">
        <div #wheelcanvas id="canvasContainer" [@bounce]="{ value: bounce }">
            <canvas id='wheel' width='670' height='670' data-responsiveMinWidth="300" data-responsiveScaleHeight="true" data-responsiveMargin="0">Canvas not supported, use another browser. </canvas>
            <img id="prizePointer" src="assets/images/wheel_pointer.png" alt="V" />
            <img id="priceBoard" src="assets/images/wheel_back.png" alt="V" />
        </div>
    </div>
</section>