import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApisService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    form!: FormGroup;
    loading = false;
    submitted = false;

    username: any = '';
    password: any = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private api: ApisService
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        console.log(this.username);

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        //this.loading = true;
        if(this.username == 'admin' && this.password == 'bytescrafter2023*') {
            localStorage.setItem('logged', 'override');
        }
        location.reload();

        // this.api.posts('/v1/api/EventRaffle/play_raffle', {
        //     id: ""
        // }).then((res: any) => {
        //     if(res && res.success && res.winner) {
        
        //     } else {
                
        //     }
        //     this.loading = false;
        // }).catch(error => {
        //     console.log('error', error);
        //     this.loading = false;
        // });
    }

    back_to_home() {
        this.router.navigateByUrl('/');
    }
}
