<!--speaker section start-->
<section class="event speaker" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 style="font-family: BrandOtf;"><span>guests</span></h2>
                    </div>
                    <div class="sub-title">
                        <P style="color: #4b4b4b;">More <span>Stars</span> than ever - never miss a chance to witness the <span>Brightest</span> event of the year. Together with the prominent personalities in the <span>Philippine Entertainment Industry</span>.</P>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-sm-6 speker-container" *ngFor="let data of experts" style="margin-bottom: 30px;">
                <div class="text-center">
                    <div class="team-img">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <!-- <div class="social">
                            <ul>
                                <li><a href="#"><i class="fa fa-facebook center-content" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter center-content" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-instagram center-content" aria-hidden="true"></i></a></li>
                            </ul>
                        </div> -->
                    </div>
                    <div class="employee">
                       <!-- <h5 class="e-name text-center">{{data.name}}</h5> -->
                        <!-- <h6 class="post text-center ">{{data.designation}}</h6> -->
                        <h6 class="post text-center ">???</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--speaker section end-->
