import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApisService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-participate',
  templateUrl: './participate.component.html',
  styleUrls: ['./participate.component.scss']
})
export class ParticipateComponent implements OnInit {

  public formEnabled: boolean = false;

  public greeting: any;
  public message: any;

  public first_name: any = '';
  public last_name: any = '';
  public phone: any = '';
  public email: any = '';
  public remarks: any = '';

  private pid: any = '';
  private uid: any = '';

  public raffle_title: any = '';
  public raffle_description: any = '';

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private api: ApisService
  ) { 
    this.router.params.subscribe( data => {
      this.validate_raffle(data.id);
    });
  }

  ngOnInit(): void {
  }

  validate_raffle(id: any) {
    //console.log(id);

    //If empty then redirect.
    if(id === '') {
      this.route.navigateByUrl('/');
    }

    //api check to validate if actual participant and used.
    this.api.posts('/v1/api/EventRaffle/validate_raffle', {
      raffle_id: id
    }).then((res: any) => {
      if(res && res.success) {
        this.pid = res.data.id;
        this.uid = res.data.uuid;

        this.raffle_title = res.data.title;
        this.raffle_description = res.data.description;
      } else {
        this.route.navigateByUrl('/');
      }
    }).catch(error => {
      this.route.navigateByUrl('/');
      console.log('error', error);
    });

    //if the participant id is validated and is not yet assigned to a user, then enable the form.
    this.formEnabled = true; 
  }

  submit_entry() {
    //api check to validate if actual participant and used.
    this.api.posts('/v1/api/EventRaffle/join_raffle', {
      id: this.pid, 
      raffle_id: this.uid,
      first_name: this.first_name,
      last_name: this.last_name,
      email_address: this.email,
      phone_number: this.phone,
      remarks: this.remarks
    }).then((res: any) => {
      if(res && res.success) {
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone = '';
        this.remarks = '';

        this.greeting = res.message;
      } else {
        this.message = res.message;
      }
    }).catch(error => {
      this.message = "Something went wrong!";
      console.log('error', error);
    });
  }

  back_to_home() {
    this.route.navigateByUrl('/');
  }

}
