import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-pricing',
  templateUrl: './event-pricing.component.html',
  styleUrls: ['./event-pricing.component.scss']
})
export class EventPricingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  pricing = [
    { 
      icon:"diamond",
      img: "assets/images/event/endorser/1.png",
      package:"reseller",
      feature1:"Lorem ipsum dolor",
      feature2:"Nulla pulvinar in",
      feature3:"Phasellus non",
      price:"0",
      btn:"purchase"
    },
    {
      icon:"diamond",
      img: "assets/images/event/endorser/2.png",
      package:"distributor",
      feature1:"Lorem ipsum dolor",
      feature2:"Nulla pulvinar in",
      feature3:"Phasellus non",
      price:"49",
      btn:"purchase"
    },
    {
      icon:"diamond",
      img: "assets/images/event/endorser/3.png",
      package:"Business",
      feature1:"Lorem ipsum dolor",
      feature2:"Nulla pulvinar in",
      feature3:"Phasellus non",
      price:"99",
      btn:"purchase"
    },
    {
      icon:"diamond",
      img: "assets/images/event/endorser/4.png",
      package:"Business",
      feature1:"Lorem ipsum dolor",
      feature2:"Nulla pulvinar in",
      feature3:"Phasellus non",
      price:"99",
      btn:"purchase"
    },
    {
      icon:"diamond",
      img: "assets/images/event/endorser/5.png",
      package:"Business",
      feature1:"Lorem ipsum dolor",
      feature2:"Nulla pulvinar in",
      feature3:"Phasellus non",
      price:"99",
      btn:"purchase"
    }
  ]
  
  pricingcarouselOptions= {
    items: 3,
    margin: 30,
    nav: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    dots: false,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        480: {
            items: 3,
            margin: 10
        },
        992: {
            items: 4,
            margin: 10
        }
    }
  }

}
