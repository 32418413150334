<div class="container">
  <div class="row">
    <div class="col-3"></div>
    <div class="col-6 card">
      <h4 class="card-header">Welcome! Admin</h4>
      <div class="card-body">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="mb-3">
                  <label class="form-label">Username</label>
                  <input type="text" formControlName="username" class="form-control" [(ngModel)]="username"/>
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Username is required</div>
                  </div>
              </div>
              <div class="mb-3">
                  <label class="form-label">Password</label>
                  <input type="password" formControlName="password" class="form-control" [(ngModel)]="password"/>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
              </div>
              <div class="text-center">
                  <button [disabled]="loading" class="btn btn-primary">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                      Authenticate
                  </button>
              </div>
              <div class="text-center" style="margin-top: 20px;">
                <a class="m-0-auto" (click)="back_to_home()">Back to Home</a>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>