<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-center">CONGRATULATIONS!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p style="text-align: center; padding: 20px 0; color: #3f3f3f;">{{message}}</p>
	</div>
	<div class="modal-footer" style="display: block; font-size: 10px;">
		<div class="text-center text-black">Made Possible by BytesCrafter</div>
	</div>
</ng-template>

<section class="event header" [ngStyle]="{'background-image': 'url(assets/images/event/wheel-bg.jpg)'}">
    <div class="header3 bg">
        <div class="container" style="margin: initial;">
            <div class="row">
                <ul class="grid-container" #holder>
                    <li class="grid-item" id="m-{{item.id}}" [@bounce]="{ value: bounce }" *ngFor="let item of data">
                        <div class="box" [style.font-weight]="getFontWeight(item)" [style.font-size]="getFontSize(item)" [style.color]="getFontColor(item)" [style.background-color]="getBackground(item)" [ngStyle]="{'background-image': 'url(assets/images/mosaic_item.png)'}">
                            {{ item.name }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>