import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './raffle/login/login.component';
import { HomeComponent } from './raffle/home/home.component';
import { SpinnerComponent } from './raffle/spinner/spinner.component';
import { WheelComponent } from './raffle/wheel/wheel.component';
import { MosaicComponent } from './raffle/mosaic/mosaic.component';
import { AuthGuard } from './../guard/auth.guard';
import { LoginGuard } from './../guard/login.guard';
import { JoinComponent } from './raffle/join/join.component';
import { EventsComponent } from './events/events.component';
import { ParticipateComponent } from './raffle/participate/participate.component';

const routes: Routes = [
  {
    path: '',
    component: EventsComponent,
    data: {
      title: "BSEI | Raffle"
    }
  },
  {
    path: 'join/:id',
    component: JoinComponent
  },
  {
    path: 'bsei/:id',
    component: ParticipateComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'spinner',
        component: SpinnerComponent
      },
      {
        path: 'wheel',
        component: WheelComponent
      },
      {
        path: 'mosaic',
        component: MosaicComponent
      },
    ],
    canActivate: [AuthGuard]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
