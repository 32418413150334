import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApisService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-event-subscribe',
  templateUrl: './event-subscribe.component.html',
  styleUrls: ['./event-subscribe.component.scss']
})
export class EventSubscribeComponent implements OnInit {
  @ViewChild('content') content: Element;
  message: any = '';
  notif: any = '';

  public first_name: any = '';
  public last_name: any = '';
  public phone_number: any = '';
  public email_address: any = '';

  constructor(
    private api: ApisService,
    private modal: NgbModal,
  ) { }

  ngOnInit() {
  }

  showGreeting(message: any) {
    this.notif = message;
    this.modal.open(this.content, {centered: true});
  }

  subscribe() {

    //api check to validate if actual participant and used.
    this.api.posts('/v1/api/EventRaffle/subscribe_now', {
      first_name: this.first_name,
      last_name: this.last_name,
      phone_number: this.phone_number,
      email_address: this.email_address,
    }).then((res: any) => {
      if(res && res.success) {
        this.first_name = '';
        this.last_name = '';
        this.phone_number = '';
        this.email_address = '';
        
        this.showGreeting(res.message);
      } else {
        this.showGreeting(res.message);
      }
    }).catch(error => {
      console.log('error', error);
    });
  }
}
