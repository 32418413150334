<!-- <app-event-nav></app-event-nav>
<app-event-header></app-event-header> -->

<app-event-about></app-event-about>
<!-- <app-event-speaker></app-event-speaker> -->
<!-- <app-event-blog></app-event-blog> -->
<!-- <app-event-booking></app-event-booking> -->

<app-event-schedule></app-event-schedule>
<app-event-testimonial></app-event-testimonial>
<app-event-subscribe></app-event-subscribe>
<!-- <app-event-pricing></app-event-pricing> -->
<!-- <app-event-gallery></app-event-gallery> -->

<!-- <app-event-contact></app-event-contact> -->
<!-- <app-event-counter></app-event-counter> -->
<app-event-sponsor></app-event-sponsor>
<app-event-copyright></app-event-copyright>