import {
  OnInit,
  Component
} from '@angular/core';
import { HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/shared/service/api.service';
import { ConfettiService } from '../../../services/confetti.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
}) 

export class HomeComponent implements OnInit {

  curPage: any = 0;
  raffles: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private confetti: ConfettiService,
    private api: ApisService
  ) {
    route.queryParams.subscribe(p => {
      //console.log(p)
    });

    this.api.posts('/v1/api/EventRaffle/list_raffle', {
      eventid: 109
    }).then((res: any) => {
      if(res && res.data) {
        this.raffles = res.data;
        this.raffles.unshift({
          raffle_type: "home"
        });
      }
    }).catch(error => {
      console.log('error', error);
    });
  }
  
  ngOnInit() {
    
  }

  prevPage() {
    if(this.curPage > 0) {
      this.curPage --;
    }
  }

  nextPage() {
    if(this.curPage < (this.raffles.length-1)) {
      this.curPage ++;
    }
  }

  fromChildInit(eData: any) {
    //console.log('From child');
    //console.log(eData);
  }

  fromMainPage(uuid: any) {
    //console.log(uuid);
    this.raffles.forEach((element, index) => { 
      if(uuid == element.uuid) {
        this.curPage = index;
      }
    })
  }

  backToMainPage() {
    this.curPage = 0;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    //console.log(event.key);

    if(event.key == ",") {
      this.confetti.showConfettiSide();
    }
    if(event.key == ".") {
      this.confetti.showConfettiStar();
    }
    if(event.key == "/") {
      this.confetti.showConfettiFirewords();
    }
  }
}