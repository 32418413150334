import { Component, OnInit } from '@angular/core';
import { ApisService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-event-testimonial',
  templateUrl: './event-testimonial.component.html',
  styleUrls: ['./event-testimonial.component.scss']
})
export class EventTestimonialComponent implements OnInit {

  testimonialCarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    dots: false,
    navText: ['<img src="assets/images/music/gallery/gallery-icon/left.png">', '<img src="assets/images/music/gallery/gallery-icon/right.png">'],
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true
  }

  users = []
  
  constructor(
    private api: ApisService
  ) { 
    this.loadData();
  }

  ngOnInit() {
  }

  async loadData() {
    //api check to validate if actual participant and used.
    this.api.posts('/v1/api/EventRaffle/list_winners', {}).then((res: any) => {
      
      if(res && res.data) {
        for(var i=0; i < res.data.length; i++) {
          this.users.push({
            img:"assets/images/prize/motor.png",
            fname: res.data[i].user_name,
            rname: res.data[i].raffle_name,
            event: res.data[i].event_name
          });
        }
      }

    }).catch(error => {
      console.log('error', error);
    });
  }
}
