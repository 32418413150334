import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  public isLoading: any = false;

  constructor(
    private http: HttpClient
  ) { }

  jsonUrlEncode(element, key?, list?) {
    let newList = list || [];
    if (typeof element === 'object') {
      for (let idx in element) {
        this.jsonUrlEncode(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          newList
        );
      }
    } else {
      newList.push(key + '=' + encodeURIComponent(element));
    }
    return newList.join('&');
  }

  requestJsonp(url, callback = 'callback') {
    return this.http.jsonp(environment.baseUrl, callback);
  }

  post(fullurl, body): Promise<any> {
    this.isLoading = true;
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.jsonUrlEncode(body);
      return this.http.post(fullurl, param, header).subscribe((data) => {
        resolve(data);
        this.isLoading = false;
      }, error => {
        resolve(error);
        this.isLoading = false;
      });
    });
  }

  posts(url, body): Promise<any> {
    this.isLoading = true;
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.jsonUrlEncode(body);
      return this.http.post(environment.baseUrl + url, param, header).subscribe((data) => {
        resolve(data);
        this.isLoading = false;
      }, error => {
        resolve(error);
        this.isLoading = false;
      });
    });
  }

}
