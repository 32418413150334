<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Notification</h4>
		<!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
	</div>
	<div class="modal-body">
		<p style="text-align: center; padding: 20px 0; color: #3f3f3f;">{{notif}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<!--subscribe section start-->
<section class="event subscribe subscribe-3 set-relative">
    <!-- <div class="circle">
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 style="font-family: BrandOtf;">Brilliant Subscription</h2>
                    </div>
                    <div class="sub-title">
                        <P style="color: #4b4b4b;">Your Ticket to Exclusive Raffles and Irresistible Prizes! Unlock a world of exclusive opportunities with our free raffle Subscription. Join our community of subscribers and elevate your chances of winning big. </P>
                    </div>
                </div>
            </div>
            <div class="col-10 offset-1">
                <div class="bg-white">

                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group m-t-20">
                                    <input type="text" class="form-control" [(ngModel)]="first_name" name="first_name" placeholder="First Name *" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group m-t-20">
                                    <input type="text" class="form-control" [(ngModel)]="last_name" name="last_name" placeholder="Last Name *" required>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group m-t-20">
                                    <input type="text" class="form-control" [(ngModel)]="phone_number" name="phone_number" placeholder="Phone Number *" required>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group m-t-20">
                                    <input type="email" class="form-control" [(ngModel)]="email_address" name="email_address" placeholder="Email Address *" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 text-center m-t-40">
                                <a class="btn btn-default primary-btn text-white" (click)="subscribe()">Join Community</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--subscribe section end-->
