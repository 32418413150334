<!-- header Start-->
<section class="event header" id="home">
    <div class="header3 bg" [ngStyle]="{'background-image': 'url(assets/images/event/event_slider.jpg)'}">
        <div class="event-content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="center-text" [@bounce]="{ value: bounce }">
                            <div class="text-center w-100">
                                <div class="h1-margin">
                                    <h1 class="header-title"><span style="font-size: larger; color: #d52696;">BRILLIANT RAFFLE</span></h1>
                                    <h3 style="color: #d52696;">#LabourDay2024 </h3>
                                    <button (click)="logout()" style="margin-top: 20px;">Logout</button>
                                </div>
                                <div class="wedding-time scrollbox">
                                    <div class="row">
                                        <div class="col-sm-3 col-12" *ngFor="let current of raffles; index as page;">
                                            <div class="countdown-container" style="padding: 20px 30px;">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <!-- <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{page}}</span>
                                                        </h2> -->
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="titles text-dark" (click)="onClickTitle(current.uuid)"><h5>{{current.title}}</h5></span>
                                                        <span class="details text-dark">Raffle: {{current.raffle_type.toUpperCase()}}</span>
                                                        <span class="details text-dark">Winners: {{current.winners}}</span>
                                                        <span class="details text-dark mt-3">{{current.crowd_type}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="set-bottom set-abs">
            <a class="center-content down" (click)="onRedirect($event)">
                <img src="assets/images/event/image-down.png" alt="" class="scroll-down">
            </a>
        </div> -->
    </div>
</section>
<!-- header end-->