import { Component, ElementRef, OnInit, QueryList, AfterViewInit, ViewChild, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, useAnimation, AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { pulse, flash } from 'ng-animate';
import { HostListener } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfettiService } from 'src/app/services/confetti.service';
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

@Component({
  selector: 'app-mosaic',
  templateUrl: './mosaic.component.html',
  styleUrls: ['./mosaic.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(pulse))])
  ],
})
export class MosaicComponent implements OnInit, AfterViewInit {
  @ViewChild('holder') holder: ElementRef<HTMLElement>;
  @ViewChild('content') content: ElementRef;

  excite: any;
  bounce: any;
  public data: any[] = [];
  capacity: any = 120;

  prevItem: any = null;
  secondsToComplete: any = 50;
  message: any;

  epass: any;
  winner: any;

  @Input() instance: any;
  @Output() trigger = new EventEmitter<any>();

  constructor(
    private config: NgbModalConfig,
    private modal: NgbModal,
    private animationBuilder: AnimationBuilder,
    private confetti: ConfettiService
  ) {
    config.backdrop = 'static';
		config.keyboard = false;
  }

  ngOnInit(): void {
    console.log(this.instance);
    this.trigger.emit(this.instance);
  }

  ngAfterViewInit() {
    //TODO: Get the participants plus the winner.
    this.generateParticipants();
  }

  async generateParticipants() {
    let total: number = this.capacity;

    for(var i=0; i < total; i++) {
      await this.getOne(i.toString()[0], i);
    }

    this.playAnim(this.holder.nativeElement);

    this.winner = 36;
    this.epass = {
      uuid: "43E5A275-6113-4DAE-BD7C-C3F5C3551D1D",
      fullname: "Juan Dela Cruz",
      seat: "Patron Area - Seat #123"
    };
  }

  async getOne(data, id) {
    await delay(1);
    this.data.push({
      "id": id, 
      "name": "0"+id.toString()[0],
      "active": false
    });
  }

  getFontWeight(item) {
    if(item.active) {
      return "bold";
    }
    return "500";
  }

  getBackground(item) {
    if(item.active) {
      return "#ffffff";
    }
    return "unset";
  }

  getFontColor(item) {
    if(item.active) {
      return "#d52696";
    }
    return "#3d3d3d";
  }

  getFontSize(item) {
    if(item.active) {
      return "17px";
    }
    return "15px";
  }

  playAnim(element: HTMLElement): void {

    const animPlayer = this.animationBuilder
      .build(flash)
      .create(element);//, { params: { ...this.params } });

    animPlayer.init();
    animPlayer.onDone(() => {
      animPlayer.destroy();
    });
    animPlayer.play();
  }

  spinSound() {
    let audio = new Audio();
    audio.src = "assets/sounds/tick.mp3";
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  }

  startLuckyDraw() {
    this.data[this.winner].active = false;

    let delay = 50;
    let timer = setInterval( () => {
      delay += 25;

      this.spinSound();
      this.randomPick();

      if(delay >= (delay%1000) * this.secondsToComplete) {
        clearInterval(timer);
        this.prevItem.active = false;
        this.onRaffleCompleted();
      }
    }, delay)
  }

  randomPick() {
    //Deactivate previous.
    if(this.prevItem) {
      this.prevItem.active = false;
    }
    
    //Activate current.
    let curItem = this.data[Math.floor(Math.random()*this.data.length)];
    curItem.active = true;
    this.prevItem = curItem;
  }

  onRaffleCompleted() {
    this.data[this.winner].active = true;
    this.winner = this.epass;
    this.confetti.showConfettiFirewords();
    this.playAnim(this.holder.nativeElement);
    this.showGreeting('The winner for the {{RAFFLE DRAW TITLE HERE}} is '+this.epass.fullname+'.');
  }

  showGreeting(message: any) {
    this.message = message;
    this.modal.open(this.content, {centered: true});
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    console.log(event.key);
    if(event.key == " ") {
      this.startLuckyDraw();
    }
  }
}
