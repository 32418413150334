import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApisService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-event-schedule',
  templateUrl: './event-schedule.component.html',
  styleUrls: ['./event-schedule.component.scss']
})
export class EventScheduleComponent implements OnInit {

  schedule = []

  constructor(
    private api: ApisService,
    private dpipe: DatePipe
  ) { 
    this.loadData();
  }

  ngOnInit() {
  }

  async loadData() {
    //api check to validate if actual participant and used.
    this.api.posts('/v1/api/EventRaffle/list_raffle', {
      eventid: 109,
      start_date: this.dateToday(),
      end_date: this.dateToday()
    }).then((res: any) => {
      if(res && res.data) {
        for(var i=0; i < res.data.length; i++) {
          this.schedule.push({
            name: res.data[i].title,
            description: res.data[i].description,
            event: res.data[i].event_id ? res.data[i].event_name : 'No Event Specified',
            time: this.dpipe.transform(res.data[i].draw_date, 'h:mm a', 'UTC-8'),
            winners: res.data[i].winners
          });
        }
      }
    }).catch(error => {
      console.log('error', error);
    });
  }

  dateToday() {
    const dateNow = Date.now();
    return this.dpipe.transform(dateNow, 'yyyy-MM-dd', 'UTC-0');
  }

  getDateToday() {
    const timeElapsed = Date.now();
    return this.dpipe.transform(timeElapsed, 'EEEE, MMMM d, y', 'UTC-8');
  }
}
